export default {
  computed: {
    theme() {
      return (window.envWebpack || {}).theme || "krediska";
    },
    toColorName() {
      return this.themeDic(
        {
          beriberu: "red",
          hurma: "orange",
          dabank: "green",
        },
        ""
      );
    },
    toColor() {
      return this.themeDic(
        {
          beriberu: "#bd2b3d",
          hurma: "#F05B01",
          krediska: "#007AFF",
          dabank: "#00C308",
        },
        ""
      );
    },
    toPhone() {
      return this.themeDic(
        {
          beriberu: {
            link: "+78005501740",
            format: "8 800 550-17-40",
          },
          krediska: {
            link: "+78006009676",
            format: "8 800 600-96-76",
          },
          hurma: {
            link: "+78005507268",
            format: "8 800 550-72-68",
          },
          dabank: {
            link: "+78047006690",
            format: "8 804 700-66-90",
          },
        },
        ""
      );
    },
  },
  methods: {
    themeDic(dic, def) {
      return dic[this.theme] || def;
    },
  },
};
